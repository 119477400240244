<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          TestReports
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-select v-model="query.grade" placeholder="Grade" class="mr10" :clearable="true">
          <el-option v-for="item in options.gradeList" :key="item.value" :label="item.description"
            :value="item.value"></el-option>
        </el-select>
        <el-select v-model="query.term" placeholder="Term" class="mr10" :clearable="true">
          <el-option v-for="item in options.termList" :key="item.value" :label="item.description"
            :value="item.value"></el-option>
        </el-select>
        <el-input v-model="query.name" placeholder="Report Name" class="handle-input mr10" @keyup.enter="handleSearch"
          :clearable="true"></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
        <el-button type="primary" @click="handleCreate" style="float: right">
          <el-icon>
            <plus />
          </el-icon>
          Create a TestReport
        </el-button>
      </div>
      <el-tabs v-model="reportStatusActiveName" :before-leave="handleReportStatusTabChange">
        <el-tab-pane label="Active" name="activeReport"></el-tab-pane>
        <el-tab-pane label="Finished" name="finishedReport"></el-tab-pane>
      </el-tabs>
      <el-table :data="getTableData()" border class="table" ref="multipleTable" header-cell-class-name="table-header"
        @sort-change="changeSort" :default-sort="{ prop: 'UpdatedTime', order: 'descending' }">
        <el-table-column prop="name" label="Report Name" sortable="true"
          :sort-orders="['ascending', 'descending', null]" :sort-by="Name"></el-table-column>
        <el-table-column prop="roomName" label="Room Name" :width="this.$widthRatio * 240 + 'px'"></el-table-column>
        <el-table-column prop="grade" label="Grade" :width="this.$widthRatio * 80 + 'px'" sortable="true"
          :sort-orders="['ascending', 'descending', null]" :sort-by="Grade"></el-table-column>
        <el-table-column prop="term" label="Term" :width="this.$widthRatio * 80 + 'px'"></el-table-column>
        <el-table-column label="Status" :width="this.$widthRatio * 120 + 'px'">
          <template #default="scope">
            <el-tag class="ml-2" :type="showTestReportStatusTagType(scope.$index, scope.row)">
              {{ scope.row.status }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="updatedTime" sortable="true" :sort-orders="['ascending', 'descending', null]"
          :sort-by="UpdatedTime" label="Updated Time" :width="this.$widthRatio * 120 + 'px'"
          :formatter="$tableCellFormatter.formatDate"></el-table-column>
        <el-table-column label="Operation" :width="this.$widthRatio * 200 + 'px'" align="center">
          <template #default="scope">
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)">
              <el-icon>
                <edit />
              </el-icon>
              Edit
            </el-button>
            <el-button type="text" @click="manageTestRecords(scope.$index, scope.row)">
              <el-icon>
                <ticket />
              </el-icon>
              Grades
            </el-button>
            <el-button type="text" @click="publishReport(scope.$index, scope.row)">
              <el-icon>
                <cloudy />
              </el-icon>
              Publish
            </el-button>
            <el-button v-if="scope.row.status === 'Published'" type="text" class="red"
              @click="issuePoints(scope.$index, scope.row, 'preview')">
              <el-icon>
                <coin />
              </el-icon>
              Issue Points
            </el-button>
            <el-button type="text" @click="showTestReport(scope.$index, scope.row)">
              <el-icon>
                <histogram />
              </el-icon>
              Report
            </el-button>
            <el-button type="text" class="red" @click="handleDelete(scope.$index, scope.row)">
              <el-icon>
                <delete />
              </el-icon>
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination" v-if="this.reportStatusActiveName === 'finishedReport'">
        <el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
          :page-size="query.pageSize" :total="query.itemTotal" :page-count="query.pageTotal"
          @current-change="handlePageChange"></el-pagination>
      </div>

      <el-dialog :title="dialogTitle" v-model="editVisible" :destroy-on-close="true" :lock-scroll="false"
        :append-to-body="true" :close-on-click-modal="false" width="90%">
        <el-form ref="mainForm" :model="form" :rules="rules" :label-width="this.$widthRatio * 120 + 'px'">
          <el-form-item prop="room" label="ClassRoom">
            <el-select v-model="form.roomId" placeholder="Please select..." @change="handleClassRoomChange">
              <el-option v-for="item in options.classRoomList" :key="item.roomId" :label="item.name"
                :value="item.roomId"
                :style="`background-color:${item.activeStatus === 1024 ? this.unstartBGColor : item.activeStatus === 2048 ? this.activeBGColor : '#FFF'};`"></el-option>
            </el-select>
            <div
              :style="`margin-left:10px;padding:0 5px;display: inline-block;background-color: ${this.activeBGColor};`">
              Active</div>
            <div
              :style="`margin-left:10px;padding:0 5px;display: inline-block;background-color: ${this.unstartBGColor};`">
              Unstarted
            </div>
          </el-form-item>
          <el-form-item prop="name" label="Report Name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item prop="grade" label="Grade">
            <el-select :disabled="true" v-model="form.grade" placeholder="Please select...">
              <el-option v-for="item in options.gradeList" :key="item.value" :label="item.description"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="term" label="Term">
            <el-select :disabled="true" v-model="form.term" placeholder="Please select...">
              <el-option v-for="item in options.termList" :key="item.value" :label="item.description"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="PTS Raw Score">
            <el-input-number v-model="form.foundationRawScore" :precision="0" :step="5" :min="0" :max="100"
              :controls="false" placeholder="Foundation Raw Score" class="mr10"></el-input-number>
            <el-input-number v-model="form.developmentRawScore" :precision="0" :step="5" :min="0" :max="100"
              :controls="false" placeholder="Development Raw Score" class="mr10"></el-input-number>
            <el-input-number v-model="form.enrichmentRawScore" :precision="0" :step="5" :min="0" :max="100"
              :controls="false" placeholder="Enrichment Raw Score" class="mr10"></el-input-number>
          </el-form-item>
          <el-form-item prop="status" label="Status">
            <el-select v-model="form.status" placeholder="Please select...">
              <el-option label="Published" value="Published"></el-option>
              <el-option label="Finished" value="Finished"></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <el-tabs v-model="activeName" v-if="form.recordList && form.recordList.length">
          <el-tab-pane label="Records" name="studentRecordsTab">
            <manage-test-records :reportData="form" :recordList="form.recordList" />
          </el-tab-pane>
        </el-tabs>

        <div class="el-dialog__footer">
          <el-button @click="editVisible = false">Cancel</el-button>
          <el-button type="primary" @click="saveEdit">Save</el-button>
        </div>
      </el-dialog>

      <el-dialog ref="testRecordsDialog" :title="manageTestRecordsDialogTitle" v-model="showManageTestRecordsDialog"
        :lock-scroll="false" :append-to-body="true" :close-on-click-modal="false" width="90%">
        <manage-test-records :reportData="currentReport" :recordList="currentReport.recordList"
          :destroy-on-close="true" />
        <div class="el-dialog__footer">
          <el-button @click="showManageTestRecordsDialog = false"> Cancel </el-button>
          <el-button type="primary" @click="saveRecords">Save</el-button>
        </div>
      </el-dialog>

      <el-dialog ref="testRecordReportDialog" :title="testRecordReportDialogTitle" v-model="showTestRecordReportDialog"
        width="90%">
        <test-report-chart :isForAdmin="true" :reportId="currentReport.reportId" :destroy-on-close="true" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getData, putData, postData, deleteData } from "../../service/api";
import ManageTestRecords from "./ManageTestRecords.vue";
import TestReportChart from "../common/TestReportChart.vue";

export default {
  components: { ManageTestRecords, TestReportChart },
  name: "testReportList",
  data() {
    return {
      controllerUrl: "/testReport",
      activeReportQuery: {
        reportId: null,
        roomId: null,
        name: null,
        grade: null,
        term: null,
        status: "Published",
        orderBy: "Grade",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: -1,
        itemTotal: 0,
        pageTotal: 0,
      },
      finishedReportQuery: {
        reportId: null,
        roomId: null,
        name: null,
        grade: null,
        term: null,
        status: "Finished",
        orderBy: "Grade",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: 20,
        itemTotal: 0,
        pageTotal: 0,
      },
      query: this.activeReportQuery,
      options: {
        gradeList: [],
        termList: [],
        classRoomList: [],
      },
      activeBGColor: "#73d0ff",
      unstartBGColor: "#9cdb9c",
      reportStatusActiveName: "activeReport",
      activeReportList: [],
      finishedReportList: [],
      form: {},
      activeName: "studentRecordsTab",
      dialogTitle: "",
      currentReport: null,
      editVisible: false,
      editMode: false,
      showManageTestRecordsDialog: false,
      manageTestRecordsDialogTitle: "",
      showTestRecordReportDialog: false,
      testRecordReportDialogTitle: "",
      rules: {
        name: [
          {
            required: true,
            message: "Please input the Name",
            trigger: "blur",
          },
        ],
        grade: [
          {
            required: true,
            message: "Please select the Grade",
            trigger: "change",
          },
        ],
        term: [
          {
            required: true,
            message: "Please select the Term",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "Please select the status",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.activeBGColor = this.$appSetting.getGenericValue("CLASSROOM_ACTIVE_BGCOLOR", "#73d0ff")
    this.unstartBGColor = this.$appSetting.getGenericValue("CLASSROOM_UNSTRAT_BGCOLOR", "#9cdb9c")

    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    this.options.termList = this.$appSetting.globalConfig.termList;

    let roomId = this.$route.query.roomId;
    let createNewReport = this.$route.query.t;
    console.debug("TestReportList.created", roomId);

    if (roomId) {
      this.query.roomId = roomId;

      if (createNewReport === "T") {
        this.loadClassRoomAndStudents(roomId);
      }
    }

    this.loadAllClassRooms();
    this.query = this.activeReportQuery;
    this.loadData(this.query);
  },
  methods: {
    loadData(query) {
      getData(this.controllerUrl, query).then((res) => {
        if (res.result && res.code === "200") {
          let resData = res.result;
          this[`${this.reportStatusActiveName}List`] = resData.list;
          console.log("loadData", this.activeClassRoomList, this.unstartedClassRoomList, this.finishedClassRoomList);
          query.pageIndex = resData.pageIndex;
          query.pageSize = resData.pageSize;
          query.itemTotal = resData.count;
          query.pageTotal = Math.ceil(resData.count / resData.pageSize);
        }
      });
    },
    loadClassRoomAndStudents(roomId) {
      getData(`classRoom/getClassRoomAndStudentsIn/${roomId}`).then((res) => {
        console.log("loadClassRoomAndStudents", res);
        if (res.result && res.code === "200") {
          let resData = res.result;
          this.form = {
            reportId: null,
            name: `Test Report for ClassRoom: ${resData.name} (Grade: ${resData.grade}, Term: ${resData.term})`,
            roomId: resData.roomId,
            roomName: resData.name,
            grade: resData.grade,
            term: resData.term,
            foundationPercentage: 0,
            developmentPercentage: 0,
            enrichmentPercentage: 0,
            foundationRawScore: 0,
            developmentRawScore: 0,
            enrichmentRawScore: 0,
            status: "Published",
          };
          this.form.recordList = [];
          if (resData.studentList && resData.studentList.length > 0) {
            for (let i = 0; i < resData.studentList.length; i++) {
              let student = resData.studentList[i];
              let record = {
                reportId: null,
                studentId: student.studentId,
                studentName: student.studentName,
                foundationRawScore: null,
                developmentRawScore: null,
                enrichmentRawScore: null,
                foundation: null,
                development: null,
                enrichment: null,
                bonus: null,
                total: null,
              };
              this.form.recordList.push(record);
            }
          }
        }
      });
    },
    loadAllClassRooms() {
      getData(`classRoom/getAllClassRoomIdAndNameForReceipt`, null, {
        isShowLoading: false,
      }).then((res) => {
        let list = res.result;
        list = list.filter((e) => e.activeStatus === 2048)
        list.sort((x, y) => {
          return (x.gradeValue * 10 + x.termValue) - (y.gradeValue * 10 + y.termValue)
        });
        this.options.classRoomList = list;
      });
    },
    handleClassRoomChange(roomId) {
      console.log("handleClassRoomChange", arguments);
      this.loadClassRoomAndStudents(roomId);
    },
    handleSearch() {
      let query = this[`${this.reportStatusActiveName}Query`]
      query.pageIndex = 1;
      this.loadData(query);
    },
    changeSort(sortData) {
      let query = this[`${this.reportStatusActiveName}Query`]
      if (sortData.order) {
        query.orderBy = sortData.prop;
        query.orderDirection = sortData.order === "ascending" ? "asc" : "desc";
      } else {
        query.orderBy = "";
        query.orderDirection = "";
      }
      this.loadData(query);
    },
    handlePageChange(val) {
      let query = this[`${this.reportStatusActiveName}Query`]
      query.pageIndex = val;
      this.loadData(query);
    },
    handleDelete(index, row) {
      // twice to confirm to delete
      this.$confirm("Are you sure to delete this item?", "Prompt", {
        type: "warning",
      })
        .then(() => {
          console.log(row);
          deleteData(this.controllerUrl, row.reportId).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Delete successfully");
              this.tableData.splice(index, 1);
            } else {
              this.$message.error("Delete failed, error message: " + res.message);
            }
          });
        })
        .catch(() => { });
    },
    publishReport(index, row) {
      // twice to confirm to publish
      this.$confirm("Are you sure to publish this report and against records", "Prompt", {
        type: "warning",
      })
        .then(() => {
          console.log(row);
          putData(`${this.controllerUrl}/publishReport`, row.reportId).then((res) => {
            if (res.result && res.code === "200") {
              row.status = "Published";
              this.editVisible = false;
              this.$message.success("Publish successfully");

              if (this.reportStatusActiveName == "activeReport") {
                this.finishedReportList = null;
              } else {
                this.activeReportList = null;
              }
              this.loadData(this.query);
            } else {
              this.$message.error("Publish failed, error message: " + res.message);
            }
          });
        })
        .catch(() => { });
    },
    handleEdit(index, row) {
      console.log(row);
      this.dialogTitle = "Edit";
      getData(`${this.controllerUrl}/getTestReportAndAllRecords/${row.reportId}`).then(
        (res) => {
          if (res.result && res.code === "200") {
            this.form = res.result;
            this.editMode = true;
            this.editVisible = true;
          }
        }
      );
    },
    manageTestRecords(index, row) {
      getData(`${this.controllerUrl}/getTestReportAndAllRecords/${row.reportId}`).then(
        (res) => {
          if (res.result && res.code === "200") {
            this.currentReport = res.result;
            this.manageTestRecordsDialogTitle = `Manage Students' test records for the report '${this.currentReport.name}'`;
            this.showManageTestRecordsDialog = true;
          }
        }
      );
    },
    showTestReport(index, row) {
      this.currentReport = row;
      this.testRecordReportDialogTitle = `The report for the test records '${this.currentReport.name}'`;
      this.showTestRecordReportDialog = true;
    },
    issuePoints(index, row, action) {
      postData(`${this.controllerUrl}/issuePoints/${row.reportId}?action=${action}`).then(
        (res) => {
          if (res.result && res.code === "200") {
            if (action === "confirm") {
              this.$message.success(res.result);
            } else {
              // twice to confirm to issue
              this.$confirm(
                "Please confirm below messages: <br>" + res.result,
                "Prompt",
                {
                  type: "warning",
                  dangerouslyUseHTMLString: true,
                }
              ).then(() => {
                this.issuePoints(index, row, "confirm");
              });
            }
          } else {
            this.$message.error("Issue Points failed, error message: " + res.message);
          }
        }
      );
    },
    handleCreate() {
      this.form = { grade: 6, term: 1, status: "Normal", recordList: [] };
      this.dialogTitle = "New";
      this.editVisible = true;
      this.editMode = false;
    },
    saveRecords() {
      postData(
        `${this.controllerUrl}/updateTestRecordsWithPendingStatus/${this.currentReport.reportId}`,
        this.currentReport.recordList
      ).then((res) => {
        console.log(res);
        if (res.result && res.code === "200") {
          this.showManageTestRecordsDialog = false;
          this.$message.success("Students' test records updated successfully");
          this.form = res.result;

          if (this.reportStatusActiveName == "activeReport") {
            this.finishedReportList = null;
          } else {
            this.activeReportList = null;
          }
          this.loadData(this.query);
        } else {
          this.$message.error("Update failed, error message: " + res.message);
        }
      });
    },
    saveEdit() {
      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          if (this.form.reportId && this.form.reportId.length === 22) {
            if (this.form.status !== "Finished") this.form.status = "Published";
            putData(this.controllerUrl, this.form.reportId, this.form).then((res) => {
              if (res.result && res.code === "200") {
                this.editVisible = false;
                this.$message.success("Update successfully");

                if (this.reportStatusActiveName == "activeReport") {
                  this.finishedReportList = null;
                } else {
                  this.activeReportList = null;
                }
                this.loadData(this.query);
              } else {
                this.$message.error("Update failed, error message: " + res.message);
              }
            });
          } else {
            this.form.status = "Published";
            postData(this.controllerUrl, this.form).then((res) => {
              console.log(res);
              if (res.result && res.code === "200") {
                this.editVisible = false;
                this.$message.success("Create successfully");
                this.form = res.result;

                if (this.reportStatusActiveName == "activeReport") {
                  this.finishedReportList = null;
                } else {
                  this.activeReportList = null;
                }
                this.loadData(this.query);
              } else {
                this.$message.error("Create failed, error message: " + res.message);
              }
            });
          }
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
    showTestReportStatusTagType(index, row) {
      if (row.status !== "Published") {
        return "info";
      }
      return "success";
    },
    getTableData() {
      return this[`${this.reportStatusActiveName}List`];
    },
    handleReportStatusTabChange(activeName, oldActiveName) {
      console.log("handleTabChange", activeName, oldActiveName);
      let query = this[`${activeName}Query`]
      this.query = query;
      let list = this[`${activeName}List`];
      if (!(list && list.length > 0)) {
        this.loadData(query);
      }
      return true;
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 1.17rem;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
